export enum SystemMessage {
    GlobalErrorUnhandled = 'Oops, we encountered a problem. If you continue to encounter this error please contact the support team at IT.Policy@EMCIns.com or call at extension 2555.',
    NotAvailableMessage = 'N/A',
    NoResultsFound = 'No results found',
    ServiceUnavailable = 'Not available at the moment',
    AddSuccess = 'Successfully Added',
    UpdateSuccess = 'Successfully Updated',
    DeleteConfirmation = `Are you sure you want to delete ?`,
    DeleteSuccess = 'Successfully Deleted'
}
