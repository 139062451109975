import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  errorMessage: string;

  constructor() {
  }

  ngOnInit() {
    this.errorMessage = 'Unexpected error occurred due to invalid route parameters.';
  }
}
