import { DecimalPipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { AppMaterialModule } from './app.material.module';
import { GlobalErrorHandler } from './shared/utilities/globalErrorHandler/global-error-handler';
import { AppConfigService } from './shared/services/appConfig/appConfig.service';
import { SharedModule } from './shared/shared.module';
import { RoutingModule } from './routing.module';
import { HomeComponent } from './components/unsubscribe.component';

export function initializeApp(appConfigService: AppConfigService): () => Promise<void> {

  return () => new Promise<void>((resolve, reject) => {
    appConfigService.loadConfig().then(
      () => {
        resolve();
      }
    ).catch((exception) => {
      const message = 'Configuration load failure:' + exception;
      reject(message);
    });
  });
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
  ],
  imports: [
    BrowserModule,
    AppMaterialModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    RoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ],
  providers: [
    { provide: 'BASE_URL', useFactory: getBaseUrl },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    AppConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfigService],
      multi: true
    },
    DecimalPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}
