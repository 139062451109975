import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Observable} from 'rxjs';
import { AppConfigService } from '../shared/services/appConfig/appConfig.service';
import { ServiceResponse } from 'src/app/shared/models/serviceResponse';
import { UnsubscribeDto } from '../model/unsubscribeDto';

@Injectable({
  providedIn: 'root'
})
export class UnsubscribeService {
  private basePath: string;
  endpoint: string;

  constructor(public http: HttpClient) {
    this.basePath = AppConfigService.settings.subscriptionApiUrl;
    this.endpoint = this.basePath.concat(`/api/experience/samples`);
  }

  public get(): Observable<ServiceResponse<string[]>> {
    return this.http.get<ServiceResponse<string[]>>(this.endpoint);
  }

  public optout(unsubscribeDto: UnsubscribeDto): Observable<ServiceResponse<boolean>> {
    const fullPath: string = this.basePath.concat(`/api/CommunicationPreference/optout`);
    return this.http.post<ServiceResponse<boolean>>(fullPath, unsubscribeDto);
  }
}
