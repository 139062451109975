import { Injectable, Inject } from '@angular/core';
import { AppConfig } from './appConfig';
import ConfigLocal from '../../../../assets/appConfig/config.local.json';
import ConfigDev from '../../../../assets/appConfig/config.dev.json';
import ConfigTest from '../../../../assets/appConfig/config.test.json';
import ConfigUat from '../../../../assets/appConfig/config.uat.json';
import ConfigBeta from '../../../../assets/appConfig/config.beta.json';
import ConfigProd from '../../../../assets/appConfig/config.prod.json';

@Injectable()
export class AppConfigService {
  static settings: AppConfig;
  private basePath: string;

  constructor(@Inject('BASE_URL') basePath: string) {
    this.basePath = basePath;

  }

  // NOTE: Called from app initializer. Initializer is designed to block when the init method returns a Promise and until it is complete.
  // Without this return type the app will start loading and referencing these settings before they have loaded.
  public loadConfig(): Promise<any> {

    if (this.basePath.indexOf('dev') > 0) {
      return this.loadSettingsFromFile(ConfigDev);
  }

  if (this.basePath.indexOf('test') > 0) {
      return this.loadSettingsFromFile(ConfigTest);
  }

  if (this.basePath.indexOf('uat') > 0) {
      return this.loadSettingsFromFile(ConfigUat);
  }

  if (this.basePath.indexOf('beta') > 0) {
      return this.loadSettingsFromFile(ConfigBeta);
  }

  if (this.basePath.startsWith('https://mailsubscription')) {
      return this.loadSettingsFromFile(ConfigProd);
  }

  return this.loadSettingsFromFile(ConfigLocal);
  }

  public loadSettingsFromFile(config): Promise<any> {
    AppConfigService.settings = <AppConfig>config;
    AppConfigService.settings.msalConfig.redirectUri = window.location.origin;
    return Promise.resolve(true);
  }
}

