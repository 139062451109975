import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorComponent, UnauthorizedComponent } from './shared';
import { HomeComponent } from './components/unsubscribe.component';

const siteTitleAppName = 'MailSubscription';
const routes: Routes = [
  { path: '', redirectTo: 'unsubscribe', pathMatch: 'full' },
  { path: 'unsubscribe/:email/:hash', component: HomeComponent },
  { path: 'error', component: ErrorComponent, data: { title:  'Error - ' + siteTitleAppName } },
  { path: 'unauthorized/:type', component: UnauthorizedComponent, data: { title:  'Unauthorized - ' + siteTitleAppName } },
  { path: 'unauthorized', component: UnauthorizedComponent, data: { title: 'Unauthorized - ' + siteTitleAppName } },
  { path: '**', component: ErrorComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class RoutingModule { }
