import { Component, OnInit } from '@angular/core';
import { SnackBarService } from 'src/app/shared/services/snackBarService/snackBarService';
import { ActivatedRoute } from '@angular/router';
import { UnsubscribeService } from './unsubscribe.service';
import { CommonDialogBoxService, DialogActionType } from 'src/app/shared';
import { UnsubscribeDto } from '../model/unsubscribeDto';

@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.scss'],
})
export class HomeComponent {

  email: string;
  hash: string;
  confirmationMessage: string = '';

  constructor(private route: ActivatedRoute,
    public unsubscribeService: UnsubscribeService,
    private snackBarService: SnackBarService
  ) {
  }

  submit() {
    this.email = this.route.snapshot.paramMap.get('email');
    this.hash = this.route.snapshot.paramMap.get('hash');

    let unsubscribeDto = new UnsubscribeDto();
    unsubscribeDto.email = this.email;
    unsubscribeDto.hash = this.hash;

    this.unsubscribeService.optout(unsubscribeDto).subscribe(response => {

    if (response !== null && response.error !== null) {
        if (!response.error.isError) {
          this.confirmationMessage = 'You have opted out of EMC News. Please close the window';
         } else {
          this.snackBarService.showError(response.error.errors.join('<br />'));
        }
      }
    });
  }
}
