import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent {
  message = '';

  UnauthorizedMessage = 'You are currently not authorized to access this application. Please contact your administrator. If you just received access it may take up to 30 minutes to synchronize.';
  UnauthorizedMessagePage = 'You are currently not authorized to access this part of the application. Please contact your administrator.';
  UnauthorizedMultiRoleMessage = 'You are currently not authorized to access this application. Multiple Security signons found for the user. Please contact your administrator.';
  UnauthorizedNoRoleMessage = 'You are currently not authorized to access this application. No Security signons found for the user. Please contact your administrator.';

  constructor(activeRoute: ActivatedRoute) {

    activeRoute.params.subscribe(params => {
      if (params['type'] === 'multi_role') {
        this.message = this.UnauthorizedMultiRoleMessage;
      } else if (params['type'] === 'no_role') {
        this.message = this.UnauthorizedNoRoleMessage;
      } else if (params['type'] === 'page') {
          this.message = this.UnauthorizedMessagePage;
      } else {
        this.message = this.UnauthorizedMessage;
      }
    });
  }

}
