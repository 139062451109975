import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class SnackBarService {
  constructor(private snackBar: MatSnackBar) {}

  showMessage(message: string, isError: boolean = false) {
    this.snackBar.open(message, '', {
      panelClass: isError ? ['snackbar-error-text'] : ['snackbar-success-text'],
      duration: 4000
    });
    document.getElementsByTagName("snack-bar-container")[0].id = 'snackbarMessage';
  }

  showError(message: string) {
    this.snackBar.open(message, 'DISMISS', {
      panelClass: ['snackbar-error-text']
    });
    document.getElementsByTagName("snack-bar-container")[0].id = 'snackbarError';
  }

  dismiss() {
    this.snackBar.dismiss();
  }
}
