import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { DialogBoxData } from './common-dialog-box-data';
import { CommonDialogBoxComponent } from './common-dialog-box.component';
import { DialogActionType } from './dialog-action-type.enum';

@Injectable()
export class CommonDialogBoxService {

  constructor(private dialogBox: MatDialog) {
  }

 openDialog(description?: string, actionButtonLabel?: string, actionType?: DialogActionType | null): Observable<boolean> {
   const dialogBoxData: DialogBoxData = new DialogBoxData(description, actionButtonLabel, actionType);
   const dialogConfig = new MatDialogConfig;
   dialogConfig.disableClose = true;
   dialogConfig.minWidth = 200;
   dialogConfig.maxWidth = 560;
   dialogConfig.panelClass = 'dialog-no-title';
   dialogConfig.data = {
     dialogBoxData
    };
    return this.dialogBox.open(CommonDialogBoxComponent, dialogConfig).afterClosed();
  }
}
