import { DialogActionType } from './dialog-action-type.enum';

export class DialogBoxData {
    public description: string;
    public actionButtonLabel: string;
    public actionType: DialogActionType;

    constructor (description: string, actionButtonLabel?: string, actionType?: DialogActionType | null) {
        this.description = description;
        this.actionType = actionType;
        this.actionButtonLabel = (actionButtonLabel == null) ? actionType.valueOf() : actionButtonLabel;
    }
}
