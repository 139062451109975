import { NgModule } from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatCardModule} from '@angular/material/card';
import {MatDividerModule} from '@angular/material/divider';
import {MatListModule} from '@angular/material/list';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatTabsModule} from '@angular/material/tabs';
import {MatDialogModule} from '@angular/material/dialog';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatMenuModule} from '@angular/material/menu';
import {MatBadgeModule} from '@angular/material/badge';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatRadioModule} from '@angular/material/radio';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatTooltipModule} from '@angular/material/tooltip';
import { MatOptionModule} from '@angular/material/core';
import { MatNativeDateModule} from '@angular/material/core';
import {MatStepperModule} from '@angular/material/stepper';

@NgModule({
    imports:
        [
            MatButtonModule,
            MatCheckboxModule,
            MatFormFieldModule,
            MatAutocompleteModule,
            MatOptionModule,
            MatDatepickerModule,
            MatNativeDateModule,
            MatInputModule,
            MatSelectModule,
            MatCardModule,
            MatDividerModule,
            MatListModule,
            MatSlideToggleModule,
            MatTabsModule,
            MatDialogModule,
            MatPaginatorModule,
            MatProgressSpinnerModule,
            MatSortModule,
            MatTableModule,
            MatSnackBarModule,
            MatToolbarModule,
            MatSidenavModule,
            MatIconModule,
            MatProgressBarModule,
            MatMenuModule,
            MatBadgeModule,
            MatGridListModule,
            MatRadioModule,
            MatExpansionModule,
            MatTooltipModule,
            MatStepperModule
        ],
    exports:
        [
            MatButtonModule,
            MatCheckboxModule,
            MatFormFieldModule,
            MatAutocompleteModule,
            MatOptionModule,
            MatDatepickerModule,
            MatNativeDateModule,
            MatInputModule,
            MatSelectModule,
            MatCardModule,
            MatDividerModule,
            MatListModule,
            MatSlideToggleModule,
            MatTabsModule,
            MatDialogModule,
            MatPaginatorModule,
            MatProgressSpinnerModule,
            MatSortModule,
            MatTableModule,
            MatSnackBarModule,
            MatToolbarModule,
            MatSidenavModule,
            MatIconModule,
            MatProgressBarModule,
            MatMenuModule,
            MatBadgeModule,
            MatGridListModule,
            MatRadioModule,
            MatExpansionModule,
            MatTooltipModule,
            MatStepperModule
        ]
})

export class AppMaterialModule {
}
