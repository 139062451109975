import { Component, OnInit } from '@angular/core';
import { AppConfigService } from './shared/services/appConfig/appConfig.service';
import { Router } from '@angular/router';
import { MsalBroadcastService } from '@azure/msal-angular';
import { User } from './shared/models/user';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

  title = 'Communication Preferences';
  envName: string;
  isLoading: boolean;
  navLinks: any[];
  activeLinkIndex = -1;
  hasFullAccess: boolean;
  isUserAuthenticated: boolean;
  isUnauthorizedOrErrorPage: boolean;
  currentYear: number;
  user: User = new User();
  isIframe = false;

  constructor(
    public router: Router
  ) {
    this.envName = AppConfigService.settings.envName;
  }

  ngOnInit(): void {

     this.navLinks = [
      {
        label: 'Unsubscribe',
        link: 'unsubscribe',
        index: 0,
        disabled: false
      }
    ];

    this.router.events.subscribe(() => {
      this.activeLinkIndex = this.navLinks.indexOf(this.navLinks.find(tab => tab.link === '.' + this.router.url));
    });
  }
}
