import { ErrorHandler, Injector, Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { SnackBarService } from '../../services/snackBarService/snackBarService';
import { ErrorModel } from '../../models/errorModel';


@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector,
    private zone: NgZone
    ) { }

  handleError(error: any) {

    const statusText = error ? error.statusText : '';

    if (statusText === 'Bad Request') {
      let snackBarService = this.injector.get(SnackBarService);
      snackBarService.showError('Invalid request');
    } else {
      let errorModel = new ErrorModel();
      errorModel.message = error;
      console.log(errorModel);

      if (error !== 'User login is required') {
        const router = this.injector.get(Router);
        // user will be redirected to unauthorized page if user not logged in
        this.zone.run(() => router.navigate(['/error']));
      }
    }
  }
}
