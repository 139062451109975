import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { CommonDialogBoxComponent } from './components/common-dialog-box/common-dialog-box.component';
import { ErrorComponent } from './components/error/error.component';
import { LoadingScreenComponent } from './components/loading-screen/loading-screen.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { CommonDialogBoxService } from './components/common-dialog-box/common-dialog-box.service';
import { LoadingScreenService } from './components/loading-screen/loading-screen.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppMaterialModule } from '../app.material.module';
import { AppConfigService, SnackBarService } from '.';
import { LoadingScreenInterceptor } from './interceptors/httpInterceptors/loading-screen.interceptor';

@NgModule({
  declarations: [CommonDialogBoxComponent, ErrorComponent, LoadingScreenComponent,
  ProgressBarComponent, UnauthorizedComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppMaterialModule
  ],
  providers: [
    AppConfigService,
    SnackBarService,
    CommonDialogBoxService,
    LoadingScreenService,
    DecimalPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingScreenInterceptor,
      multi: true
    }
  ],
  exports: [CommonDialogBoxComponent, ErrorComponent, LoadingScreenComponent,
    ProgressBarComponent, UnauthorizedComponent],
})
export class SharedModule { }
