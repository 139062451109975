import { Component, Inject, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogActionType } from './dialog-action-type.enum';

@Component({
  selector: 'app-common-dialog-box',
  templateUrl: './common-dialog-box.component.html',
  styleUrls: ['./common-dialog-box.component.scss']
})
export class CommonDialogBoxComponent {
  description: string;
  actionButtonLabel: string;
  action: DialogActionType;
  isHidden = false;

  constructor(public dialogRef: MatDialogRef<CommonDialogBoxComponent>, @Inject(MAT_DIALOG_DATA) private data) {

    this.description = data.dialogBoxData.description;
    this.action = data.dialogBoxData.actionType;
    this.actionButtonLabel = data.dialogBoxData.actionButtonLabel;

    if (this.action === DialogActionType.SingleButtonConfirmation || this.action === DialogActionType.Warning) {
      this.isHidden = true;
    }

    if (this.description == null) {
      if (this.action === DialogActionType.DeleteConfirmation) {
        this.description = 'Are you sure you want to delete?';
      } else if (this.action === DialogActionType.DiscardConfirmation) {
        this.description = 'You have unsaved changes. Are you sure you want to discard?';
      } else if (this.action === DialogActionType.ClearConfirmation) {
        this.description = 'Clear entire page?';
      }
    }
  }

  close(response: string) {
    let returnValue = false;

    if (response === 'confirm') {
      returnValue = true;
    }

    this.dialogRef.close(returnValue);
  }
}
