import { SafeUrl } from '@angular/platform-browser';
export class User {
  firstName: string;
  lastName: string;
  userName: string;
  imageUrl: SafeUrl;
  email: string;
  loginName: string;

  constructor(init?: Partial<User>) {
    Object.assign(this, init);
  }
}
