import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AppConfigService, LoadingScreenService } from '../..';

@Injectable()
export class LoadingScreenInterceptor implements HttpInterceptor {

  activeRequests = 0;

  /**
   * URLs for which the loading screen should not be enabled. This will be loaded from appConfig.
   */
  skipUrls: string[];

  constructor(private loadingScreenService: LoadingScreenService) {
    this.skipUrls = AppConfigService.settings.skipUrls;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let displayLoadingScreen = true;

    for (const skipUrls of this.skipUrls) {
      if (new RegExp(skipUrls).test(request.url)) {
        displayLoadingScreen = false;
        break;
      }
    }
    if (request.headers.has('Skip-Loading-Screen')) {
      const headers = request.headers.delete('Skip-Loading-Screen');
      request = request.clone({ headers });
      return next.handle(request);
    }

    if (displayLoadingScreen) {
      if (this.activeRequests === 0) {
        this.loadingScreenService.startLoading();
      }
      this.activeRequests++;

      return next.handle(request).pipe(
        finalize(() => {
          this.activeRequests--;
          if (this.activeRequests === 0) {
            this.loadingScreenService.stopLoading();
          }
        })
      );
    } else {
      return next.handle(request);
    }
  }
}
