import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent {
  constructor(
    public dialogRef: MatDialogRef<ProgressBarComponent>, @Inject(MAT_DIALOG_DATA) public length: number) {
    this.percentComplete = 0;
    this.itemsComplete = 0;
    this.totalItemCount = length;
  }
  itemsComplete: number;
  totalItemCount: number;
  percentComplete: number;

  advanceProgress(itemsCompleted: number = 1) {
    this.itemsComplete = this.itemsComplete + itemsCompleted;
    this.calculatePercentComplete();
  }

  reset() {
    this.percentComplete = 0;
    this.itemsComplete = 0;
  }

  initialize(length: number) {
    this.totalItemCount = length;
  }

  calculatePercentComplete() {
    if (this.totalItemCount === 0) {
      return;
    }
    this.percentComplete = Math.floor(this.itemsComplete / this.totalItemCount * 100);
  }
}
